import {useEffect, useState} from 'react';
import styles from './TryThese.module.css';
import {webApi} from '../../../api/webApi';
import stylesSkelton from '../../styles/skeleton.module.css';
import cn from 'classnames';
import {useNavigate} from 'react-router-dom';
import {webEventsApi} from '../../../amplitude/webEvents';
import {UseCase} from '../../../api/types';
import {useScrollBtns} from '../../ScrollButtons/useScrollBtns';

export const TryThese = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [useCases, setUseCases] = useState<UseCase[]>([]);
	const [wasError, setWasError] = useState(false);

	const {scrollContainerRef, scrollLeftVisible, scrollRightVisible} =
		useScrollBtns({showOnTouch: false});

	useEffect(() => {
		setIsLoading(true);
		webApi
			.useCases()
			.then((res) => {
				if (res.data) {
					setUseCases(res.data);
				} else {
					setWasError(true);
				}
			})
			.catch((err) => {
				console.error(err);
				setWasError(true);
			})
			.finally(() => setIsLoading(false));
	}, []);

	if ((!isLoading && useCases.length === 0) || wasError) {
		return null;
	}

	const middleIndex = Math.floor(useCases.length / 2);
	const firstHalf = useCases.slice(0, middleIndex);
	const secondHalf = useCases.slice(middleIndex);

	return (
		<div className={styles.container}>
			<h3 className={styles.title}>Explore the universe of ai characters</h3>
			<div
				className={cn(styles.useCasesRows, {
					[styles.leftVisible]: scrollLeftVisible && !isLoading,
					[styles.rightVisible]: scrollRightVisible && !isLoading,
				})}
				ref={isLoading ? null : scrollContainerRef}
			>
				<div className={styles.useCases}>
					{isLoading &&
						Array.from({length: 3}).map((_, index) => (
							<UseCaseSkeleton index={`row1-${index}`} key={`row1-${index}`} />
						))}
					{!isLoading &&
						firstHalf.map((useCase, index) => (
							<UseCaseComponent useCase={useCase} index={index} key={index} />
						))}
				</div>
				<div className={styles.useCases}>
					{isLoading &&
						Array.from({length: 3}).map((_, index) => (
							<UseCaseSkeleton index={`row2-${index}`} key={`row2-${index}`} />
						))}
					{!isLoading &&
						secondHalf.map((useCase, index) => (
							<UseCaseComponent useCase={useCase} index={index} key={index} />
						))}
				</div>
			</div>
		</div>
	);

	// return (
	// 	<div className={styles.container}>
	// 		<h3 className={styles.title}>Try These</h3>
	// 		<div className={styles.useCases}>
	// 			{isLoading &&
	// 				Array.from({length: 8}).map((_, index) => (
	// 					<UseCaseSkeleton index={index} key={index} />
	// 				))}
	// 			{!isLoading &&
	// 				useCases.map((useCase, index) => (
	// 					<UseCaseComponent useCase={useCase} index={index} key={index} />
	// 				))}
	// 		</div>
	// 	</div>
	// );
};

const UseCaseSkeleton = ({index}: {index: number | string}) => {
	return (
		<div
			className={cn(styles.useCase, stylesSkelton.skeleton)}
			key={index}
			style={{
				width: `${Math.floor(Math.random() * (300 - 240 + 1)) + 240}px`,
			}}
		/>
	);
};

const UseCaseComponent = ({
	useCase,
	index,
}: {
	useCase: UseCase;
	index: number;
}) => {
	const navigate = useNavigate();
	const handleClick = () => {
		webEventsApi.exploreUsecaseTapped({
			bot_id: useCase.attributes.bot?.data?.id,
			bot_name: useCase.attributes.bot?.data?.attributes?.name,
			usecase_text: useCase.attributes.name,
			index,
		});
		navigate(`/bot_${useCase.attributes.bot?.data?.id}/chat`);
	};

	return (
		<div className={styles.useCase} onClick={handleClick}>
			<div className={styles.useCaseImageContainer}>
				<img
					src={useCase.attributes.icon.data?.attributes.url}
					alt={useCase.attributes.bot.data?.attributes?.name}
					className={styles.useCaseImage}
				/>
			</div>
			<h4 className={styles.useCaseTitle}>{useCase.attributes.name}</h4>
		</div>
	);
};
