import {useEffect, useState} from 'react';
import styles from './ActionOptionsBtn.module.css';
import cn from 'classnames';
import threeDots from './images/threeDots.svg';
import {Switcher} from '../../common-lib/src/components/Switcher/Switcher';

type Props = {
	icon?: string;
	noCloseIds?: string[];
};

export const ActionOptionsBtn = ({
	icon = threeDots,
	children,
	noCloseIds,
}: React.PropsWithChildren<Props>) => {
	const [isOpened, setIsOpened] = useState(false);

	const handleClose = (e: MouseEvent) => {
		const target = e.target as HTMLElement;
		if (
			target.closest(`#action-options-btn`) ||
			noCloseIds?.includes(target.id)
		) {
			return;
		}

		setIsOpened(false);
	};

	const handleOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
		const isNewOpened = !isOpened;
		// @ts-ignore
		if (!isNewOpened && noCloseIds?.some((id) => e.target?.closest(`#${id}`))) {
			return;
		}
		setIsOpened(isNewOpened);
		if (isNewOpened) {
			document.addEventListener('click', handleClose);
		} else {
			document.removeEventListener('click', handleClose);
		}
	};

	useEffect(() => {
		return () => {
			document.removeEventListener('click', handleClose);
		};
	}, []);

	return (
		<div
			className={styles.actionOptionsBtn}
			id="action-options-btn"
			onClick={handleOpen}
		>
			<img className={styles.icon} src={icon} alt="user icon" />
			{isOpened && <div className={styles.popover}>{children}</div>}
		</div>
	);
};

export const ActionOptionsRow = ({
	icon,
	text,
	onClick,
	isRed,
	id,
}: {
	icon: string;
	text: string;
	onClick: () => void;
	isRed?: boolean;
	id?: string;
}) => {
	return (
		<div className={styles.row} onClick={onClick} id={id}>
			<img className={styles.icon} src={icon} alt={text} />
			<p className={cn(styles.text, {[styles.redText]: isRed})}>{text}</p>
		</div>
	);
};

export const ActionOptionsToggleRow = ({
	icon,
	text,
	onClick,
	isRed,
	id,
	isChecked,
	onChange,
}: {
	icon: string;
	text: string;
	onClick: () => void;
	isRed?: boolean;
	id?: string;
	isChecked: boolean;
	onChange: (value: boolean) => void;
}) => {
	return (
		<div className={styles.row} onClick={onClick} id={id}>
			<img className={styles.icon} src={icon} alt={text} />
			<p className={cn(styles.text, {[styles.redText]: isRed})}>{text}</p>
			<Switcher
				id={id}
				checked={isChecked}
				onChange={(e) => onChange(e.target.checked)}
				className={styles.switcher}
			/>
		</div>
	);
};
