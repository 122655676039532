import styles from './RandomTile.module.css';
import stylesTile from '../TileV2/TileV2.module.css';
import cn from 'classnames';
import randomImg from './images/random.webp';
import {useEffect, useState} from 'react';
import {webApi} from '../../../api/webApi';
import {BotData} from '../../../api/types';
import {useNavigate} from 'react-router-dom';
import {webEventsApi} from '../../../amplitude/webEvents';

export const RandomTile = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [randomBot, setRandomBot] = useState<BotData | null>(null);
	const navigate = useNavigate();

	const fetchRandomBot = async () => {
		setIsLoading(true);
		const randomBot = await webApi
			.getRandomBot()
			.finally(() => setIsLoading(false));
		if (randomBot?.data) {
			setRandomBot(randomBot.data);
		}
	};

	useEffect(() => {
		fetchRandomBot();
	}, []);

	if (isLoading || !randomBot) {
		return null;
	}

	const handleClick = () => {
		webEventsApi.exploreRandomTapped();
		navigate(`/bot_${randomBot.id}/chat`);
	};

	return (
		<div
			className={cn(styles.randomTile, stylesTile.container)}
			onClick={handleClick}
		>
			<img src={randomImg} alt="Random bot" className={styles.randomTileImg} />
			<p className={styles.randomTileTitle}>Random bot</p>
		</div>
	);
};
