import {webEventsApi} from '../../amplitude/webEvents';

export const useNotificationPermission = () => {
	const getPermission = () => {
		const permission = Notification?.permission;
		return permission === 'granted'
			? true
			: permission === 'denied'
			? false
			: null;
	};

	const hasPermission = () => {
		return getPermission() !== null;
	};

	const requestNotificationAccess = async () => {
		if (hasPermission()) {
			return;
		}
		try {
			webEventsApi.notificationPopupShown();
			const result = await Notification.requestPermission();
			if (result === 'granted') {
				webEventsApi.notificationsAllowed();
			} else if (result === 'denied') {
				webEventsApi.notificationsNotAllowed();
			} else {
				webEventsApi.notificationsSkipped();
			}
		} catch (e) {
			console.error(e);
		}
	};

	return {
		requestNotificationAccess,
		getPermission,
		hasPermission,
	};
};
