import {Button} from '@mui/material';
import {Spinner} from '../../../../common-lib/src/components/Spinner/Spinner';
import {MainContainer} from '../../../Main/MainContainer';
import {TileWide} from '../../../Tile/TileWide/TileWide';
import {getHeaderContent} from '../../Category';
import {useNewCategoryScreen} from '../NewCategory';
import styles from './MobileNewCategoryScreen.module.css';
import {useNavigate} from 'react-router-dom';
import {CategoryData} from '../../../../api/types';

export const MobileNewCategoryScreen = () => {
	const {isLoading, rows, categories, isMobile} = useNewCategoryScreen();
	const navigate = useNavigate();

	const handleShowAll = (data: CategoryData) => {
		navigate(`/category_${data.id}`);
	};

	return (
		<MainContainer headerContent={getHeaderContent({isMobile})}>
			{!isLoading &&
				rows.map(([key, bots], i) => {
					const c = categories[key];
					if (!c) {
						return null;
					}

					return (
						<div
							style={{
								width: '100%',
							}}
							key={key}
							className={styles.category}
						>
							<div className={styles.headerRow}>
								<h2 className={styles.header}>{c.data.attributes.name}</h2>
								<Button
									variant="text"
									className={styles.showAll}
									id={`showAll-${c.data.attributes.name}`}
									onClick={() => handleShowAll(c.data)}
								>
									Show All
								</Button>
							</div>
							{bots.map((bot, i) => {
								return <TileWide bot={bot} key={bot.id} rate={i} />;
							})}
							<Button
								className={styles.seeMore}
								id={`see-more-${c.data.attributes.name}`}
								onClick={() => handleShowAll(c.data)}
							>
								See more bots
							</Button>
						</div>
					);
				})}
			{isLoading ? <Spinner withLayout={false} noTopMargin /> : null}
		</MainContainer>
	);
};
