import {CategoryAndBots} from '../../api/apiHelper';
import {BotData} from '../../api/types';
import {useTilesRow} from '../TilesRow/TilesRow';
import {RandomTile} from './RandomTile/RandomTile';
import styles from './TilesPlateV2.module.css';
import {TileV2} from './TileV2/TileV2';

type Props = {
	bots: BotData[];
	categoryData?: CategoryAndBots;
	showRandomTile?: boolean;
};

export const TilesPlateV2 = ({
	bots,
	categoryData,
	showRandomTile = false,
}: Props) => {
	const data = categoryData?.data;
	const {handleBotClick} = useTilesRow({
		categoryData,
		bots,
		isPreview: false,
		skipEvents: false,
		index: data?.id ? Number(data.id) : 0,
	});

	return (
		<div className={styles.container}>
			{showRandomTile && bots.length > 0 && <RandomTile />}
			{bots.map((bot) => (
				<TileV2 bot={bot} key={bot.id} onClick={() => handleBotClick(bot)} />
			))}
		</div>
	);
};
