import {BotData} from '../../../../api/types';
import {UserInfo} from '../../../../api/usersApi/usersApi.types';
import {Message, ChatResponse} from '../../../../common-lib/src/api/Api';
import {
	AccessLevel,
	ChatV4Response,
} from '../../../../common-lib/src/api/Api.types';
import {convertBase64ToImage} from '../../../../common-lib/src/utils';

export const replaceMultipleNewlines = (str: string) => {
	return str.replace(/\n{3,}/g, '\n\n');
};

export const getChatType = (bot: BotData) => {
	if (!bot.attributes.firebaseUserId) {
		return 'default';
	}
	if (bot.attributes.isOwner) {
		return 'custom';
	}
	return 'custom_shared';
};

export const getSplit = (context: Message[]) => {
	return [...context].reverse().find((m) => m.turn === 'bot')?.split;
};

export const extractImageFromResponse = (res: ChatResponse) => {
	if (res.base64_image) {
		return convertBase64ToImage(res);
	}
	if (res.media_response?.media_type === 'image') {
		if (res.media_response.media_url) {
			return res.media_response.media_url;
		}
		if (res.media_response.media_id) {
			const rand = Math.floor(Math.random() * 5) + 1;
			return `/blur ${rand}.webp`;
		}
	}
	return null;
};

export const extractVideoFromResponse = (res: ChatResponse) => {
	if (res.media_response?.media_type === 'video') {
		if (res.media_response.media_url) {
			return res.media_response.media_url;
		}
		if (res.media_response.media_id) {
			return `/blur.webm`;
		}
	}
	return null;
};

const isBlured = (res: ChatResponse) => {
	return !res.base64_image && !res.media_response?.media_url;
};

export const prepareMessageFromResponse = (res: ChatResponse) => {
	const image = extractImageFromResponse(res);
	const video = extractVideoFromResponse(res);
	const message: Message = {
		message: res.response,
		turn: 'bot',
		split: res.split,
		timestamp: Date.now(),
		isSexting: !!res.is_user_msg_sexting,
		reaction: null,
	};
	if (image) {
		message.image = image;
		message.base64_image = res.base64_image!;
		message.blured = isBlured(res);
	}
	if (video) {
		message.video = video;
		message.blured = isBlured(res);
	}
	if (res.media_response?.media_id) {
		message.media_response = res.media_response;
	}

	return message;
};

export const prepareMessageFromV4Response = (
	res: ChatV4Response,
	index: number
): Message => {
	const realIndex = res.responses.length >= index ? index : 0;

	const response = res.responses[realIndex];
	const message: Message = {
		message: response.response,
		turn: 'bot',
		split: res.split,
		timestamp: Date.now(),
		isSexting: !!res.analytics_metadata.is_user_msg_sexting,
		reaction: null,
	};

	if (response.media_response) {
		message.media_response = response.media_response;

		const type = response.media_response.media_type;
		if (type === 'image') {
			message.image = response.media_response.media_url;
		}
		if (type === 'video') {
			message.video = response.media_response.media_url;
		}
		message.blured = !response.media_response.media_url;
	}

	return message;
};

export const convertV4ResponseToV2 = (res: ChatV4Response): ChatResponse => {
	return {
		base64_image: null,
		base64_video: null,
		contains_photo_request: res.analytics_metadata.contains_photo_request,
		is_user_msg_sexting: res.analytics_metadata.is_user_msg_sexting,
		response: res.responses[0].response,
		split: res.split,
		target_language: null,
		media_response: res.responses[0].media_response,
	};
};

export const checkAcessLevel = (user: UserInfo | null, level: AccessLevel) => {
	const levels: AccessLevel[] = ['basic', 'premium', 'ultra'];
	const userLevel = user?.accessLevel || 'basic';
	const userAccessLevelIndex = levels.indexOf(userLevel);
	const llmAccessLevelIndex = levels.indexOf(level);
	return userAccessLevelIndex >= llmAccessLevelIndex;
};
