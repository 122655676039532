import React, {useEffect, useRef, useState} from 'react';
import {Button, Dialog, DialogContent} from '@mui/material';
import styles from './MultiMessageSelectDialog.module.css';
import cn from 'classnames';
import {Message} from '../../../../../common-lib/src/api/Api';
import {CV4RMessage} from '../../../../../common-lib/src/api/Api.types';
import check from './images/check.svg';
import noCheck from './images/no-check.svg';
import arrow from './images/arrow.svg';
import arrowWhite from './images/arrow-white.svg';
import {useDisplaySize} from '../../../../../common-lib/src/hooks/useDisplaySize';
import {splitMessageToItalic} from '../../../../../common-lib/src/components/ChatCommon/ChatMain/utils';
import {webEventsApi} from '../../../../../amplitude/webEvents';

interface MultiMessageSelectDialogProps {
	onSubmit: (message: CV4RMessage, byUser: boolean) => void;
	userMessage?: Message | null;
	multipleMessagesSelect: CV4RMessage[];
}

export const MultiMessageSelectDialog: React.FC<
	MultiMessageSelectDialogProps
> = ({onSubmit, userMessage, multipleMessagesSelect}) => {
	const [selectedMessageIndex, setSelectedMessageIndex] = useState<number>(0);
	const {isMobile} = useDisplaySize();

	const handleClose = (byUser: boolean) => {
		onSubmit(multipleMessagesSelect[selectedMessageIndex], byUser);
		if (byUser) {
			webEventsApi.twoResponsesPopupSelected({index: selectedMessageIndex});
		}
	};

	const handleSelectMessage = (index: number) => {
		setSelectedMessageIndex(index);
	};

	useEffect(() => {
		webEventsApi.twoResponsesPopupAppear();
	}, []);

	return (
		<Dialog
			open
			onClose={() => handleClose(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			className={styles.dialog}
			fullScreen={isMobile}
			sx={
				isMobile
					? {
							maxHeight: 'fit-content',
							margin: 'auto 0 0 0',
							minHeight: '560px',
					  }
					: {}
			}
		>
			<DialogContent className={styles.dialogContent}>
				<div className={styles.content}>
					<h2 className={styles.title}>Elevate Your AI</h2>
					<h3 className={styles.subtitle}>
						Select the answer option you believe is the most suitable.
					</h3>
					<div className={styles.messagesContainer}>
						{userMessage && <UserMessage message={userMessage} />}
						{multipleMessagesSelect.map((message, index) => (
							<BotMessage
								key={index}
								message={message}
								selected={index === selectedMessageIndex}
								onClick={() => handleSelectMessage(index)}
								index={index}
							/>
						))}
					</div>
				</div>
				<Button
					onClick={() => handleClose(true)}
					className={styles.dialogButton}
				>
					Select
				</Button>
			</DialogContent>
		</Dialog>
	);
};

const UserMessage = ({message}: {message: Message}) => {
	const [expanded, setExpanded] = useState(false);
	const messageRef = useRef<HTMLDivElement>(null);
	const [isMessagedClamped, setIsMessagedClamped] = useState(false);

	useEffect(() => {
		if (messageRef.current) {
			setIsMessagedClamped(
				messageRef.current.scrollHeight > messageRef.current.clientHeight
			);
		}
	}, [messageRef]);

	const handleExpand = (e: React.MouseEvent<HTMLDivElement>) => {
		setExpanded(!expanded);
	};

	const texts = splitMessageToItalic(message.message);

	return (
		<div className={styles.userMessage}>
			<p
				className={cn(styles.userMessageText, {
					[styles.userMessageTextExpanded]: expanded,
				})}
				ref={messageRef}
			>
				{texts.map(({text, italic}) => (
					<span key={text} className={italic ? 'message__text_italic' : ''}>
						{text}
					</span>
				))}
			</p>
			{isMessagedClamped && (
				<div className={styles.messageExpand} onClick={handleExpand}>
					<img
						src={arrowWhite}
						alt="expand"
						className={cn(styles.messageExpandArrow, {
							[styles.messageExpandArrowExpanded]: expanded,
						})}
					/>
					<p
						className={cn(
							styles.messageExpandText,
							styles.messageExpandTextWhite
						)}
					>
						{expanded ? 'Collapse' : 'Expand'}
					</p>
				</div>
			)}
		</div>
	);
};

const BotMessage = ({
	message,
	selected,
	onClick,
	index,
}: {
	message: CV4RMessage;
	selected: boolean;
	onClick: () => void;
	index: number;
}) => {
	const [expanded, setExpanded] = useState(false);
	const handleExpand = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		setExpanded(!expanded);
	};
	const messageRef = useRef<HTMLDivElement>(null);
	const [isMessagedClamped, setIsMessagedClamped] = useState(false);

	useEffect(() => {
		if (messageRef.current) {
			setIsMessagedClamped(
				messageRef.current.scrollHeight > messageRef.current.clientHeight
			);
		}
	}, [messageRef]);

	useEffect(() => {
		if (expanded) {
			webEventsApi.twoResponsesPopupOptionExpanded({index});
		}
	}, [expanded]);

	const texts = splitMessageToItalic(message.response);

	return (
		<div className={styles.messageRow} onClick={onClick}>
			<div className={styles.botMessage}>
				<p className={styles.messageIndex}>Option {index + 1}</p>
				<p
					className={cn(styles.messageText, {
						[styles.messageTextExpanded]: expanded,
					})}
					ref={messageRef}
				>
					{texts.map(({text, italic}) => (
						<span key={text} className={italic ? 'message__text_italic' : ''}>
							{text}
						</span>
					))}
				</p>
				{isMessagedClamped && (
					<div className={styles.messageExpand} onClick={handleExpand}>
						<img
							src={arrow}
							alt="expand"
							className={cn(styles.messageExpandArrow, {
								[styles.messageExpandArrowExpanded]: expanded,
							})}
						/>
						<p className={styles.messageExpandText}>
							{expanded ? 'Collapse' : 'Expand'}
						</p>
					</div>
				)}
			</div>
			<div className={styles.check}>
				<img src={selected ? check : noCheck} alt="check" />
			</div>
		</div>
	);
};
