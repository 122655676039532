import './ChatMain.css';
import {Message} from '../../../api/Api';
import {ChatPerson} from '../../../data/chatData';
import resend from './images/resend.svg';
import errorImg from './images/error.svg';
import deleteImg from './images/delete-red.svg';
import deletedUserImg from './images/delete-user.svg';
import deleteBotImg from './images/delete-bot.svg';
import {useEffect, useState} from 'react';
import {Spinner} from '../../Spinner/Spinner';
import cn from 'classnames';
import {RetryFeedbackRow} from './RetryFeedbackRow/RetryFeedbackRow';
import {ActionsRow} from './ChatActionsRow';
import {ImageMessage, VideoMessage} from './ChatMediaMessage';
import {
	CommonActions,
	Actions,
	UserActions,
	VoteMessageData,
} from './ChatMain.types';
import {splitMessageToItalic} from './utils';

type MessageProps = {
	message: Message;
	blured?: boolean;
	error?: boolean;
	commonActions?: CommonActions & Partial<Actions>;
	turnEditMode?: () => void;
	handleRetry?: Actions['retry'];
} & Pick<Actions, 'unblur'>;

const getActionFromReaction = (reaction?: string | null) => {
	if (reaction === 'upvote') {
		return 'like';
	}
	if (reaction === 'downvote') {
		return 'dislike';
	}
	return null;
};

export const ChatMessage = ({
	message,
	actions,
	userActions,
	commonActions,
}: {
	message: Message;
	actions?: Actions;
	userActions?: UserActions;
	commonActions?: CommonActions;
}) => {
	const [isEditMode, setIsEditMode] = useState(false);
	const [editMessage, setEditMessage] = useState(message.message);
	const [isLoadingValidation, setIsLoadingValidation] = useState(false);
	const [isLoadingRetry, setIsLoadingRetry] = useState(false);
	const [isInvalid, setIsInvalid] = useState(false);
	const [acttionFromRetry, setActionFromRetry] = useState<
		'like' | 'dislike' | 'retry' | null
	>(getActionFromReaction(message.reaction));

	const isDisabled = editMessage === message.message;
	const isDeleted = message.isDeleted;

	const handleSubmit = async () => {
		if (isDisabled) {
			return;
		}

		if (editMessage.trim() === '') {
			setIsInvalid(true);
			return;
		}

		setIsInvalid(false);

		setIsLoadingValidation(true);
		const isValid = actions?.edit
			? await actions?.edit?.(message, editMessage)
			: await commonActions?.edit?.(message, editMessage);
		setIsLoadingValidation(false);

		if (isValid) {
			handleTurnOffEditMode();
		} else {
			setIsInvalid(true);
			actions?.scrollChat?.();
		}
	};

	const handleSetIsLoadingRetry = (value: boolean) => {
		const isDisabled = actions?.regenerateDisabled;
		if (!isDisabled) {
			setIsLoadingRetry(value);
		}
	};

	const handleRetry = async (message: Message) => {
		handleSetIsLoadingRetry(true);
		actions?.retry
			? await actions?.retry?.(message)
			: await commonActions?.retry?.(message);
		handleSetIsLoadingRetry(false);
	};

	const handleKeyDown = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === 'Enter') {
			handleSubmit();
			return;
		}
		if (e.key === 'Escape') {
			handleTurnOffEditMode();
			return;
		}
	};

	const handleChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setEditMessage(e.target.value);
		setIsInvalid(false);
	};

	useEffect(() => {
		if (isEditMode) {
			setEditMessage(message.message);
			setIsInvalid(false);
		}
	}, [isEditMode, message.message]);

	const handleTurnEditMode = () => {
		setIsEditMode(true);
		setTimeout(() => actions?.scrollChat?.(), 0);
	};

	const handleTurnOffEditMode = () => {
		if (editMessage.trim() === '') {
			setEditMessage(message.message);
		}
		setIsEditMode(false);
		setTimeout(() => actions?.scrollChat?.(), 0);
	};

	const onlyMediaMessage =
		!message.message && !!(message.video || message.image);

	const imageComponent =
		isDeleted || message.media_response?.media_type !== 'image' ? null : (
			<ImageMessage
				message={message}
				actions={actions}
				commonActions={onlyMediaMessage ? commonActions : undefined}
			/>
		);

	const videoComponent =
		isDeleted || message.media_response?.media_type !== 'video' ? null : (
			<VideoMessage message={message} actions={actions} />
		);

	if (isLoadingValidation || isLoadingRetry) {
		return (
			<>
				{isLoadingValidation ? imageComponent : null}
				{isLoadingValidation ? videoComponent : null}
				<div
					className={cn(
						'chat__message chat__message_bot chat__message_loading',
						{
							chat__message_user: message.turn === 'user',
						}
					)}
				>
					<Spinner withLayout={false} />
				</div>
			</>
		);
	}

	if (isEditMode) {
		return (
			<>
				{imageComponent}
				{videoComponent}
				<div
					className={cn('chat__message chat__message_edit', {
						chat__message_bot: message.turn === 'bot',
						chat__message_user: message.turn === 'user',
						chat__message_invalid: isInvalid,
					})}
				>
					<textarea
						className="edit-message"
						defaultValue={editMessage}
						onKeyDown={handleKeyDown}
						onChange={handleChangeText}
					/>
				</div>
				<div
					className={cn('edit-message__row', {
						'edit-message__row_user': message.turn === 'user',
						'edit-message__row_bot': message.turn === 'bot',
					})}
				>
					<button
						className="edit-message__btn edit-message__btn_cancel"
						type="button"
						onClick={handleTurnOffEditMode}
					>
						Cancel
					</button>
					<button
						className="edit-message__btn edit-message__btn_save"
						type="button"
						onClick={handleSubmit}
						disabled={isDisabled}
					>
						Save
					</button>
				</div>
			</>
		);
	}

	const handleRetryVote = (data: VoteMessageData) => {
		actions?.vote?.(data);
		if (data.vote === 'better') {
			setActionFromRetry('like');
		}
		if (data.vote === 'worse') {
			setActionFromRetry('dislike');
		}
	};

	if (
		!imageComponent &&
		!videoComponent &&
		!message.message &&
		!message.isDeleted
	) {
		return null;
	}

	return (
		<>
			{imageComponent}
			{videoComponent}
			{onlyMediaMessage ? null : (
				<SimpleMessage
					message={message}
					blured={message.blured}
					unblur={actions?.unblur}
					error={!!userActions?.onErrorClick}
					commonActions={commonActions}
					turnEditMode={handleTurnEditMode}
					handleRetry={handleRetry}
				/>
			)}
			{actions?.shouldShowRetryFeedback && (
				<RetryFeedbackRow
					onFeedback={handleRetryVote}
					onClose={actions.handleCloseRetryFeedback}
					message={message}
				/>
			)}
			{actions && !actions?.shouldShowRetryFeedback && (
				<ActionsRow
					actions={actions}
					turnEditMode={handleTurnEditMode}
					action={acttionFromRetry}
					message={message}
					handleRetry={handleRetry}
				/>
			)}
			{!!userActions?.onErrorClick && (
				<div className="actions-row actions-row_user">
					<div
						className="actions-row__item actions-row__item_with-text"
						onClick={userActions.onErrorClick}
					>
						<img src={resend} alt="resend" />
						<p>Retry</p>
					</div>
				</div>
			)}
		</>
	);
};

export const useCommonActions = (
	message: Message,
	commonActions?: CommonActions
) => {
	const isTouchDevice = 'ontouchstart' in window;
	const [isHoverOpen, setIsHoverOpen] = useState(false);

	const shouldShowHover =
		!!commonActions &&
		Object.keys(commonActions).length &&
		!message.isDeleted &&
		!isTouchDevice;
	const shouldShowHoverOnClick =
		!!commonActions &&
		Object.keys(commonActions).length &&
		!message.isDeleted &&
		isTouchDevice;

	const handleDelete = () => {
		commonActions?.handleDeleteMessage?.(message);
		setIsHoverOpen(false);
		document.removeEventListener('click', handleClosePopover);
	};

	const handleClosePopover = (e: MouseEvent) => {
		const messageEl = (e.target as HTMLElement)?.closest('.chat__message');
		const messageId = messageEl?.id;
		const currentMessageId = `message_${message.timestamp}`;
		if (messageId && messageId !== currentMessageId) {
			setIsHoverOpen(false);
		}
		if (
			!(e.target as HTMLElement)?.closest('.chat__message_delete-block') &&
			!(e.target as HTMLElement)?.closest('.chat__message')
		) {
			setIsHoverOpen(false);
		}
	};

	useEffect(() => {
		if (isHoverOpen) {
			document.addEventListener('click', handleClosePopover);
		} else {
			document.removeEventListener('click', handleClosePopover);
		}
		return () => {
			document.removeEventListener('click', handleClosePopover);
		};
	}, [isHoverOpen]);

	const handleTouchStart = (id: string) => {
		if (shouldShowHoverOnClick) {
			setIsHoverOpen(true);
		}
	};

	return {
		shouldShowHover,
		shouldShowHoverOnClick,
		handleDelete,
		handleTouchStart,
		isHoverOpen,
	};
};

export const MessageDeleteBlock = ({
	message,
	commonActions,
	isHoverOpen,
	turnEditMode,
	handleRetry,
	handleDelete,
}: {
	message: Message;
	commonActions?: CommonActions;
	isHoverOpen: boolean;
	turnEditMode?: () => void;
	handleRetry?: (message: Message) => Promise<void>;
	handleDelete: () => void;
}) => {
	return (
		<div
			className={cn('chat__message_delete-block', {
				'chat__message_delete-block_show': isHoverOpen,
			})}
		>
			<ActionsRow
				actions={commonActions as Actions}
				message={message}
				nomargin
				turnEditMode={turnEditMode}
				handleRetry={handleRetry}
			/>
			<div className="chat__message_delete-block__row" onClick={handleDelete}>
				<img src={deleteImg} alt="Delete message" />
			</div>
		</div>
	);
};

const SimpleMessage = ({
	message,
	error,
	commonActions,
	turnEditMode,
	handleRetry,
}: MessageProps) => {
	const {shouldShowHover, handleDelete, handleTouchStart, isHoverOpen} =
		useCommonActions(message, commonActions);

	const texts = splitMessageToItalic(message.message);

	return (
		<div
			className={cn('chat__message', {
				chat__message_user: message.turn === 'user',
				chat__message_bot: message.turn !== 'user',
				'chat__message_delete-hover': shouldShowHover,
			})}
			id={`message_${message.timestamp}`}
			onClick={() => handleTouchStart(`message_${message.timestamp}`)}
		>
			<MessageDeleteBlock
				message={message}
				commonActions={commonActions}
				isHoverOpen={isHoverOpen}
				turnEditMode={turnEditMode}
				handleRetry={handleRetry}
				handleDelete={handleDelete}
			/>
			{!message.isDeleted && (
				<div className="message__text">
					{texts.map(({text, italic}) => (
						<span key={text} className={italic ? 'message__text_italic' : ''}>
							{text}
						</span>
					))}
				</div>
			)}
			{message.isDeleted && (
				<div style={{display: 'flex', gap: 4}}>
					<img
						src={message.turn === 'bot' ? deleteBotImg : deletedUserImg}
						alt="Deleted message"
					/>
					<div className="message__text">You deleted this message</div>
				</div>
			)}
			{error && (
				<div className="message__error">
					<img src={errorImg} alt="Error" />
					<div className="message__text">Message sending error</div>
				</div>
			)}
		</div>
	);
};

export const MessageWithAvatar = ({
	message,
	person,
}: {
	message: Message;
	person: ChatPerson;
}) => {
	const {image} = message;

	return (
		<>
			<div className="chat__message-row">
				<img
					className="message-row__photo"
					src={person.src}
					alt={person.name}
				/>
				<SimpleMessage message={message} />
			</div>
			{image && (
				<div
					className={`chat__message chat__message_photo {message.turn === 'user' ? 'chat__message_user' : 'chat__message_bot'
						}`}
					style={{marginLeft: '40px'}}
				>
					<img
						src={image}
						alt="chat-avatar"
						className="chat__message_bot-image"
					/>
				</div>
			)}
		</>
	);
};
