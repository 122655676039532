import {webEventsApi} from '../../amplitude/webEvents';
import {api} from '../../common-lib/src/api/Api';
import {clearUtmParamsCookie, getUtmParamsCookie, isDevb2c} from '../../utils';
import {
	USERS_API_TOKEN_KEY,
	XAuthName,
} from '../../common-lib/src/api/constants';
import {CheckoutReq, Product} from '../types';
import {GtagApi} from '../gtagApi';
import {
	ChatFirebase,
	ChatFirebaseCreate,
	GetFirebaseChatsResponse,
	MessageFirebase,
	MessageFirebaseCreate,
	MigrateChatsResponse,
	UserInfo,
	UserPublicInfo,
} from './usersApi.types';
import {getAuth} from 'firebase/auth';
import {cookieHandler} from '../../common-lib/src/cookieHandler/CookieHandler';

const IS_ANONYMOUS_KEY_COOKIE = 'uia';

class UsersApi {
	url: string;
	userHeader: string | null = null;
	cache: {
		lastCall: Date;
		user: UserInfo | null;
	};

	constructor() {
		if (isDevb2c()) {
			this.url = 'https://api.dev.botify.ai/chatbot/v2';
		} else {
			this.url = 'https://api.botify.ai/chatbot/v2';
		}

		this.cache = {
			lastCall: new Date(0),
			user: null,
		};

		const cache = this.loadCache();
		if (cache) {
			this.saveToCache(cache);
		}
	}

	private getHeaders(skipAuth = false): HeadersInit {
		const headers: HeadersInit = {
			'Content-Type': 'application/json',
		};

		if (skipAuth) {
			return headers;
		}

		const token = this.getToken();
		if (token) {
			headers['Authorization'] = `Bearer ${token}`;
			headers[XAuthName] = token;
		}

		return headers;
	}

	getToken() {
		if (this.userHeader) {
			return this.userHeader;
		}
		const token = api.getCookie(USERS_API_TOKEN_KEY);
		if (!token) {
			return null;
		}

		this.userHeader = token;
		return token;
	}

	setToken(token: string) {
		this.userHeader = token;
		api.setCookie(USERS_API_TOKEN_KEY, token);
	}

	async login(token: string, {isAnonymous}: {isAnonymous?: boolean} = {}) {
		try {
			const analytics = getUtmParamsCookie();
			const result = await fetch(`${this.url}/users/login`, {
				method: 'POST',
				headers: this.getHeaders(true),
				body: JSON.stringify({token, analytics}),
			}).then((res) => res.json());

			if (result?.accessToken) {
				clearUtmParamsCookie();
				this.setToken(result.accessToken);

				if (!isAnonymous) {
					if (result.isFirstLogin) {
						GtagApi.authNewUser();
					} else {
						GtagApi.authSignIn();
					}
				}

				if (isAnonymous) {
					api.setCookie(IS_ANONYMOUS_KEY_COOKIE, 'true');
				} else {
					api.setCookie(IS_ANONYMOUS_KEY_COOKIE, 'false');
				}
				return result.accessToken;
			} else {
				throw new Error('Auth problem');
			}
		} catch (e) {
			console.error(e);
			throw new Error('Auth problem');
		}
	}

	saveToCache(user: UserInfo | null) {
		this.cache = {
			lastCall: new Date(),
			user,
		};
		sessionStorage.setItem('usersApiCache', JSON.stringify(this.cache));
	}

	loadCache(): UserInfo | null {
		const cache = sessionStorage.getItem('usersApiCache');
		if (!cache) {
			return null;
		}

		const parsed = JSON.parse(cache);
		if (
			new Date(parsed.lastCall) < new Date(new Date().getTime() - 1000 * 60 * 5)
		) {
			return null;
		}

		return parsed.user;
	}

	deleteCache() {
		this.cache = {
			lastCall: new Date(0),
			user: null,
		};
		sessionStorage.removeItem('usersApiCache');
	}

	getFromCache(): UserInfo | null {
		if (
			new Date(this.cache.lastCall) <
			new Date(new Date().getTime() - 1000 * 60 * 5)
		) {
			return null;
		}

		this.saveToCache(this.cache.user);
		return this.cache.user;
	}

	private async fetchWithAuthRetry(
		url: string,
		options: RequestInit
	): Promise<Response> {
		const response = await fetch(url, options);
		if (response.status === 401) {
			const auth = getAuth();
			const currentUser = auth.currentUser;
			if (currentUser) {
				const token = await currentUser.getIdToken();
				const loginSuccess = await this.login(token);
				if (loginSuccess) {
					this.userHeader = loginSuccess;
					options.headers = this.getHeaders();
					return fetch(url, options);
				}
			}
		}
		return response;
	}

	async me(): Promise<UserInfo | null> {
		const token = this.getToken();
		if (!token) {
			return null;
		}

		try {
			const result = await this.fetchWithAuthRetry(`${this.url}/users/me`, {
				method: 'GET',
				headers: this.getHeaders(),
			}).then((res) => {
				if (res.status === 401) {
					this.userHeader = null;
					api.setCookie(USERS_API_TOKEN_KEY, '');
					return null;
				}
				if (res.status !== 200) {
					throw new Error('Users me problem');
				}
				return res.json();
			});

			if (result as UserInfo | null) {
				this.saveToCache(result);
				api.setUserIdCookie(result.sourceSystemId, true);
				if (!result.isAnon) {
					webEventsApi.setUserId(result.sourceSystemId);
				}
			}
			return result as UserInfo | null;
		} catch (e) {
			this.userHeader = null;
			api.setCookie(USERS_API_TOKEN_KEY, '');
			console.log(e);
			throw new Error('Users me problem');
		}
	}

	async updateMe(data: Partial<UserInfo>): Promise<UserInfo | null> {
		return this.fetchWithAuthRetry(`${this.url}/users/me`, {
			method: 'PUT',
			headers: this.getHeaders(),
			body: JSON.stringify(data),
		}).then(async (res) => {
			const data = await res.json();
			if (res.status !== 200) {
				throw new Error('User update failed');
			}
			return data;
		});
	}

	async uploadAvatar(file: File): Promise<Partial<UserInfo>> {
		const formData = new FormData();
		formData.append('image', file);
		const headers = this.getHeaders() as Record<string, string>;
		delete headers['Content-Type'];

		return this.fetchWithAuthRetry(`${this.url}/users/me/avatar`, {
			method: 'POST',
			headers,
			body: formData,
		}).then(async (res) => {
			const data = await res.json();
			if (res.status !== 200) {
				throw new Error('Upload avatar problem');
			}
			return data;
		});
	}

	async updateChatPersonaAvatar(file: File): Promise<Partial<UserInfo>> {
		const formData = new FormData();
		formData.append('image', file);
		const headers = this.getHeaders() as Record<string, string>;
		delete headers['Content-Type'];

		return this.fetchWithAuthRetry(`${this.url}/users/me/persona-avatar`, {
			method: 'POST',
			headers,
			body: formData,
		}).then(async (res) => {
			const data = await res.json();
			if (res.status !== 200) {
				throw new Error('Update chat persona avatar problem');
			}
			return data;
		});
	}

	resetToken() {
		this.userHeader = null;
		api.setCookie(USERS_API_TOKEN_KEY, '');
		this.deleteCache();
	}

	async checkout({
		id,
		name,
		successUrl,
	}: CheckoutReq): Promise<{checkoutUrl: string}> {
		return this.fetchWithAuthRetry(
			`${this.url}/payments/create-checkout-session`,
			{
				method: 'POST',
				headers: this.getHeaders(),
				body: JSON.stringify({
					productId: id,
					successUrl,
					cancelUrl: `${window.location.origin}/`,
				}),
			}
		).then((res) => {
			if (res.status !== 200) {
				throw new Error('Checkout problem');
			}
			return res.json();
		});
	}

	async getSubscriptionManagementUrl(): Promise<{
		billingPortalUrl: string;
	} | null> {
		return this.fetchWithAuthRetry(`${this.url}/payments/billing-portal`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify({
				successUrl: `${window.location.origin}/`,
			}),
		})
			.then((res) => {
				if (res.status !== 200) {
					console.error(res);
					return null;
				}
				return res.json();
			})
			.catch((e) => {
				console.error(e);
				return null;
			});
	}

	async getProducts(): Promise<Product[]> {
		return this.fetchWithAuthRetry(`${this.url}/payments/products`, {
			method: 'GET',
			headers: this.getHeaders(),
		})
			.then((res) => {
				if (res.status !== 200) {
					webEventsApi.paywallLoadingProductsFailed({
						error: res?.statusText,
						error_code: res?.status,
					});
					console.error(res);
					return [];
				}
				return res.json();
			})
			.catch((e) => {
				console.error(e);
				return [];
			});
	}

	async migrateHistory(
		data: ChatFirebaseCreate[]
	): Promise<MigrateChatsResponse> {
		const url = '/chats/bulk-create';
		return this.fetchWithAuthRetry(`${this.url}${url}`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify(data),
		}).then((res) => {
			if (res.status !== 200) {
				throw new Error('Migrate history problem');
			}
			return res.json();
		});
	}

	async getChatHistory(
		chatId: string,
		{page = 0, pageSize = 3}: {page?: number; pageSize?: number} = {}
	): Promise<ChatFirebase> {
		const url = `/chats/${chatId}/messages?size=${pageSize}&page=${page}`;
		return this.fetchWithAuthRetry(`${this.url}${url}`, {
			method: 'GET',
			headers: this.getHeaders(),
		})
			.then((res) => {
				if (res.status !== 200) {
					throw new Error('Get chat history problem');
				}
				return res.json();
			})
			.then((res: GetFirebaseChatsResponse) => {
				return {
					id: chatId,
					messages: res.items,
					page: res.page,
					total: res.total,
				};
			})
			.catch((e) => {
				console.error('Get chat history problem', e);
				throw new Error('Get chat history problem');
			});
	}

	async deleteChat(chatId: string) {
		const url = `/chats/${chatId}`;
		return this.fetchWithAuthRetry(`${this.url}${url}`, {
			method: 'DELETE',
			headers: this.getHeaders(),
		}).then((res) => {
			if (res.status !== 204 && res.status !== 200) {
				throw new Error('Delete chat problem');
			}
			return;
		});
	}

	async clearChat(chatId: string) {
		const url = `/chats/${chatId}/clear-history`;
		return this.fetchWithAuthRetry(`${this.url}${url}`, {
			method: 'POST',
			headers: this.getHeaders(),
		}).then((res) => {
			if (res.status !== 204 && res.status !== 200) {
				throw new Error('Clear chat problem');
			}
			return res.json();
		});
	}

	async updateMessage(
		chatId: string,
		messageId: string,
		data: Partial<MessageFirebase> & {isLastMessage?: boolean}
	): Promise<MessageFirebase> {
		const url = `/chats/${chatId}/messages/${messageId}`;
		return this.fetchWithAuthRetry(`${this.url}${url}`, {
			method: 'PUT',
			headers: this.getHeaders(),
			body: JSON.stringify(data),
		}).then((res) => {
			if (res.status !== 200) {
				throw new Error('Update message problem');
			}
			return res.json();
		});
	}

	async deleteMessage(chatId: string, messageId: string): Promise<void> {
		const url = `/chats/${chatId}/messages/${messageId}`;
		return this.fetchWithAuthRetry(`${this.url}${url}`, {
			method: 'DELETE',
			headers: this.getHeaders(),
		}).then((res) => {
			if (res.status !== 204 && res.status !== 200) {
				throw new Error('Delete message problem');
			}
			return;
		});
	}

	async createMessage(
		chatId: string,
		data: MessageFirebaseCreate
	): Promise<MessageFirebase> {
		const url = `/chats/${chatId}/messages`;
		return this.fetchWithAuthRetry(`${this.url}${url}`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify(data),
		}).then((res) => {
			if (res.status !== 200) {
				throw new Error('Create message problem');
			}
			return res.json();
		});
	}

	async getPublicProfile(username: string): Promise<UserPublicInfo | null> {
		return this.fetchWithAuthRetry(`${this.url}/users/${username}`, {
			method: 'GET',
			headers: this.getHeaders(),
		}).then((res) => {
			if (res.status !== 200 && res.status !== 404) {
				throw new Error('Get public profile problem');
			}
			if (res.status === 404) {
				return null;
			}
			return res.json();
		});
	}

	async logout() {
		const deviceId = cookieHandler.deviceId();
		await this.fetchWithAuthRetry(`${this.url}/users/logout`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify({deviceId}),
		}).catch((e) => {
			console.error(e);
		});
	}

	async addPushToken(token: string) {
		const deviceId = cookieHandler.deviceId();
		await this.fetchWithAuthRetry(`${this.url}/users/push-token`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify({deviceId, pushToken: token}),
		}).catch((e) => {
			console.error(e);
		});
	}
}

export const usersApi = new UsersApi();
