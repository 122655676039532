import {generateUUID} from '../api/Api';

const DEVICE_ID_KEY = 'did';

export class CookieHandler {
	GCLID = 'gclid';

	setGclidCookie(gclid: string) {
		this.setCookie(this.GCLID, gclid, 30);
	}

	getGclidFromCookies(): string {
		return this.getCookie(this.GCLID) || localStorage.getItem(this.GCLID) || '';
	}

	getGASessionFromCookies(): string {
		const val = this.getCookie('_ga_W8G2PY1BJT');
		if (!val) {
			return '';
		}
		return val.split('.').at(2) || '';
	}

	getGA(): string {
		const ga = this.getCookie('_ga');

		if (!ga) {
			return '';
		}

		const gaArr = ga.split('.');
		if (gaArr.length < 4) {
			return '';
		}

		return gaArr[2] + '.' + gaArr[3];
	}

	setCookie(name: string, value: string | number, days?: number) {
		const d = days || 365;
		const options = {
			path: '/',
			samesite: 'lax',
			secure: window.location.protocol === 'https:',
			expires: new Date(Date.now() + 60 * 60 * 24 * d * 1000).toUTCString(),
		};

		value = encodeURIComponent(value);
		let updatedCookie = `${name}=${value}`;
		for (const propName in options) {
			updatedCookie += `; ${propName}`;

			//@ts-ignore
			const propValue = options[propName];
			if (propValue !== true) {
				updatedCookie += `=${propValue}`;
			}
		}
		document.cookie = updatedCookie;
	}

	private getCookie(name: string) {
		const matches = document.cookie.match(
			new RegExp(
				'(?:^|; )' +
					name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
					'=([^;]*)'
			)
		);
		return matches ? decodeURIComponent(matches[1]) : undefined;
	}

	getFbc() {
		return this.getCookie('_fbc') || localStorage.getItem('_fbc') || '';
	}

	extractParameterByName = (name: string) => {
		const urlParams = new URLSearchParams(window.location.search);
		return urlParams.get(name);
	};

	convertFbclidToFbc = (fbclid: string) => {
		return `fb.1.${Date.now()}.${fbclid}`;
	};

	gclFn = () => {
		const gclid = this.extractParameterByName('gclid');
		if (!gclid) {
			return null;
		}

		localStorage.setItem(cookieHandler.GCLID, gclid);
		cookieHandler.setGclidCookie(gclid);

		return gclid;
	};

	fbFn = () => {
		const fbclid = this.extractParameterByName('fbclid');
		if (!fbclid) {
			return null;
		}
		const fbc = this.convertFbclidToFbc(fbclid);
		localStorage.setItem('_fbc', fbc);

		return fbc;
	};

	deviceId() {
		const v = this.getCookie(DEVICE_ID_KEY);
		if (!v) {
			const id = generateUUID();
			this.setCookie(DEVICE_ID_KEY, id, 365);
			return id;
		}
		return v;
	}
}

export const cookieHandler = new CookieHandler();
