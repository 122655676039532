import {BotData} from '../../../api/types';
import {MessagesQty} from '../../../common-lib/src/components/BotTile/MessagesQty';
import styles from './TileV2.module.css';
import cn from 'classnames';
import {Link, useNavigate} from 'react-router-dom';
import stylesSkeleton from '../../styles/skeleton.module.css';

type Props = {
	bot: BotData;
	className?: string;
	onClick?: () => void;
};

export const TileV2 = ({bot, className, onClick}: Props) => {
	const navigate = useNavigate();
	const handleTileClick = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		onClick?.();
		navigate(`/bot_${bot.id}/chat`);
	};

	if (!bot) {
		return <div className={cn(styles.container, stylesSkeleton.skeleton)} />;
	}

	return (
		<Link
			to={`/bot_${bot.id}/chat`}
			className={cn(styles.container, className)}
			onClick={handleTileClick}
			style={{
				backgroundImage: `url(${
					bot.attributes.heroPicture || bot.attributes.avatarUrl
				})`,
			}}
		>
			<MessagesQty
				messagesQty={bot.attributes.messagesCount}
				className={cn('bot-tile__messages-qty', styles.qty)}
			/>

			<div className={styles.namePhotoRow}>
				<img
					className={styles.photo}
					src={bot.attributes.avatarUrl}
					alt={bot.attributes.name}
				/>
				<p className={styles.name}>{bot.attributes.name}</p>
			</div>
			{(bot.attributes.greeting || bot.attributes.bio) && (
				<p className={styles.greeting}>
					{bot.attributes.greeting || bot.attributes.bio}
				</p>
			)}
			<div className={styles.replyBtn}>
				<p className={styles.replyBtnText}>Reply</p>
			</div>
			<div className={styles.overlay} />
		</Link>
	);
};
