import {useContext, useEffect, useRef, useState} from 'react';
import {BotData} from '../../../../../api/types';
import styles from './SideBar.module.css';
import {webApi} from '../../../../../api/webApi';
import {chatStorage} from '../../../../../chatStore/ChatStorage';
import {Spinner} from '../../../../../common-lib/src/components/Spinner/Spinner';
import {Link, useLocation} from 'react-router-dom';
import cn from 'classnames';
import {discord, fb, x, instagram, reddit} from './images';
import {AppContext} from '../../../../../App';
import {GetPremiumBtn} from '../../../../GetPremiumBtn/GetPremiumBtn';
import {CreateCharacterBtn} from '../../../../CreateCharacterBtn/CreateCharacterBtn';
import {DiscoverBotsBtn} from '../../../../DiscoverBotsBtn/DiscoverBotsBtn';

const MIN_VISITED_BOTS = 10;

type SideBarProps = {
	hasDiscoverBotsBtn?: boolean;
};

export const SideBar = ({hasDiscoverBotsBtn = false}: SideBarProps) => {
	const location = useLocation();
	const [featuredBots, setFeaturedBots] = useState<BotData[]>([]);
	const {
		featuredBots: featuredBotsAll,
		loading,
		user,
		visitedBots: vb,
	} = useContext(AppContext);
	const [visitedBots, setVisitedBots] = useState<BotData[]>(vb);

	useEffect(() => {
		if (visitedBots.length >= MIN_VISITED_BOTS) {
			setFeaturedBots([]);
			return;
		}

		const featuredBotsInfo: BotData[] = featuredBotsAll
			.filter((bot) => visitedBots.findIndex((b) => b.id === bot.id) === -1)
			.slice(0, MIN_VISITED_BOTS - visitedBots.length);

		setFeaturedBots(featuredBotsInfo);
	}, [featuredBotsAll, visitedBots]);

	const [isFooterVisible, setIsFooterVisible] = useState(false);
	const footerRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsFooterVisible(entry.isIntersecting);
			},
			{
				root: null,
				threshold: 0,
			}
		);

		if (footerRef.current) {
			observer.observe(footerRef.current);
		}

		return () => {
			if (footerRef.current) {
				observer.unobserve(footerRef.current);
			}
		};
	}, [footerRef.current]);

	const selectedBotId = location.pathname.includes('bot_')
		? location.pathname.split('_')[1]
		: null;

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (loading) {
			return;
		}
		const fetchData = async () => {
			try {
				setIsLoading(true);

				let visitedIds: string[] = [];
				let botsInfo: BotData[] = [];
				if (user) {
					visitedIds = vb.map((b) => b.id.toString());
					botsInfo = vb;
				} else {
					visitedIds = chatStorage.getChatVisited();

					const promises = [];
					promises.push(
						visitedIds.length
							? webApi.getBotsByIds(visitedIds)
							: Promise.resolve({data: []})
					);

					const [botsResponse] = await Promise.all(promises);
					botsInfo = [...botsResponse.data];
					botsInfo.sort(
						(a, b) =>
							visitedIds.indexOf(a.id.toString()) -
							visitedIds.indexOf(b.id.toString())
					);
				}
				setVisitedBots(botsInfo);

				const shouldShowFeatured = visitedIds.length < MIN_VISITED_BOTS;

				if (shouldShowFeatured) {
					const featuredBotsInfo: BotData[] = featuredBotsAll
						.filter((bot) => botsInfo.findIndex((b) => b.id === bot.id) === -1)
						.slice(0, MIN_VISITED_BOTS - visitedIds.length);
					setFeaturedBots(featuredBotsInfo);
				}
			} catch (e) {
				console.error(e);
			} finally {
				setIsLoading(false);
			}
		};
		fetchData();
	}, [loading]);

	const handleClick = (e: React.MouseEvent, id: BotData['id']) => {
		e.preventDefault();
		e.stopPropagation();
		window.location.href = `/bot_${id}/chat`;
	};

	const renderBot = (bot: BotData) => {
		return (
			<Link
				key={bot.id}
				className={cn(styles.botRow, {
					[styles.selected]: selectedBotId === bot.id.toString(),
				})}
				onClick={(e) => handleClick(e, bot.id)}
				to={`/bot_${bot.id}/chat`}
			>
				<img
					className={styles.botImg}
					src={bot.attributes.avatarUrl}
					alt={`${bot.attributes.name} - 48 - ${bot.attributes.createdAt}`}
					title={bot.attributes.name}
				/>
				<div className={styles.botName}>{bot.attributes.name}</div>
			</Link>
		);
	};

	const isPremiumUser = !!user?.isPaid;

	return (
		<div className={styles.side}>
			<div className={styles.sideTop}>
				<CreateCharacterBtn className={styles.create} />
				{hasDiscoverBotsBtn && <DiscoverBotsBtn />}
			</div>
			<div
				className={cn(styles.botsList, {
					[styles.nofade]: isFooterVisible,
					[styles.botListWithPremium]: !isPremiumUser && !isLoading,
				})}
			>
				{isLoading ? (
					<Spinner withLayout={false} />
				) : (
					<>
						{visitedBots.map(renderBot)}
						{featuredBots.length > 0 && (
							<>
								<div className={styles.featuredTitle}>Featured</div>
								{featuredBots.map(renderBot)}
							</>
						)}
						<div ref={footerRef}></div>
					</>
				)}
			</div>
			<div className={styles.sideBottom}>
				{!isPremiumUser && !isLoading && (
					<GetPremiumBtn className={styles.premiumBtn} />
				)}
				<nav
					className={cn(styles.nav, {
						[styles.navNoMargin]: !isLoading,
					})}
				>
					<a
						href="https://www.facebook.com/botifyapp"
						target="_blank"
						rel="noreferrer"
					>
						<img src={fb} alt="fb" className={styles.navImg} />
					</a>
					<a
						href="https://twitter.com/botify_ai"
						target="_blank"
						rel="noreferrer"
					>
						<img src={x} alt="x" className={styles.navImg} />
					</a>
					<a
						href="http://instagram.com/botify.aiapp"
						target="_blank"
						rel="noreferrer"
					>
						<img src={instagram} alt="instagram" className={styles.navImg} />
					</a>
					<a
						href="https://discord.gg/xTMBaAVmwx"
						target="_blank"
						rel="noreferrer"
					>
						<img src={discord} alt="discord" className={styles.navImg} />
					</a>
					<a
						href="https://www.reddit.com/r/botify_ai/"
						target="_blank"
						rel="noreferrer"
					>
						<img src={reddit} alt="reddit" className={styles.navImg} />
					</a>
				</nav>
				<a
					href="https://exh.ai"
					rel="nofollow"
					className={styles.exh}
					title="Ex-Human Inc."
				>
					© 2024 Ex-Human Inc.
				</a>
			</div>
		</div>
	);
};
