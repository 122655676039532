import {Modal} from '@mui/material';
import styles from './AiAvatar.module.css';
import close from './images/close.svg';
import {InButton} from '../../../common-lib/src/components/InButton/InButton';
import placeholder from '../AvatarSelect/images/avatarPlaceholder.svg';
import refreshImg from './images/Refresh.svg';
import {useState} from 'react';
import {AiName} from './AIName/AiName';
import {api} from '../../../common-lib/src/api/Api';
import {useSnackbar} from 'notistack';
import {convertBase64ToImage} from '../../../common-lib/src/utils';
import {Spinner} from '../../../common-lib/src/components/Spinner/Spinner';
import cn from 'classnames';
import checkImg from './images/check.svg';
import {ChatPhotoStyle} from '../../../common-lib/src/api/Api.types';

export type AiAvatarProps = {
	openned: boolean;
	onClose: () => void;
	onChange: (
		file: File | null,
		value: string | null,
		style: ChatPhotoStyle
	) => void;
	oneStyle?: boolean;
};

export const useAiAvatar = ({
	onClose,
	onChange,
	oneStyle = false,
}: AiAvatarProps) => {
	const {enqueueSnackbar} = useSnackbar();
	const [image, setImage] = useState<string | null>(null);
	const [image2, setImage2] = useState<string | null>(null);
	const [generatedPrompt, setGeneratedPrompt] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [text, setText] = useState('');
	const [selectedImage, setSelectedImage] = useState<string | null>(null);
	const [chatPhotoStyle, setChatPhotoStyle] = useState<ChatPhotoStyle>(null);

	async function blobUrlToFile(blobUrl: string, fileName: string) {
		const response = await fetch(blobUrl);
		const blob = await response.blob();
		const file = new File([blob], fileName, {type: blob.type});
		return file;
	}

	const handleSetImage = () => {
		const setImage = async () => {
			const file = await blobUrlToFile(selectedImage!, 'avatar.png');
			onChange(file, selectedImage, chatPhotoStyle);
		};
		if (selectedImage) {
			return setImage();
		} else {
			return onChange(null, null, null);
		}
	};

	const handleDialogClose = async () => {
		await handleSetImage();
		onClose();
	};

	const handleSelectFirstImage = () => {
		setSelectedImage(image);
		setChatPhotoStyle('realistic');
	};

	const handleSelectSecondImage = () => {
		setSelectedImage(image2);
		setChatPhotoStyle('anime');
	};

	const handleGenerateClick = async (isrefresh = false) => {
		if (isLoading) {
			return;
		}
		try {
			setIsLoading(true);

			let hasError = false;
			const getImage = async (second: boolean) => {
				if (second && oneStyle) {
					return;
				}
				const result = await api.generateAvatarFromText(
					isrefresh && generatedPrompt ? generatedPrompt : text,
					second ? 'anime' : 'realistic'
				);

				const value = Object.values(result);
				if (!value.length || result.error || result.detail) {
					hasError = true;
					return;
				}
				const base64_image = value[0] as string;
				return convertBase64ToImage({base64_image});
			};

			const [im1, im2] = await Promise.all([getImage(false), getImage(true)]);

			if (hasError) {
				enqueueSnackbar('Something went wrong. Try again or change prompt', {
					variant: 'error',
				});
			}

			if (im1) {
				setImage(im1);
				setSelectedImage(im1);
				setChatPhotoStyle('realistic');
			}
			if (im2) {
				setImage2(im2);
				if (!im1) {
					setSelectedImage(im2);
					setChatPhotoStyle('anime');
				}
			}
		} catch (e) {
			console.error(e);
		} finally {
			setIsLoading(false);
		}
	};

	const handleChangeText = (value: string) => {
		setGeneratedPrompt(null);
		setImage(null);
		setImage2(null);
		setSelectedImage(null);
		setText(value);
	};

	const handleRefreshClick = () => {
		handleGenerateClick(true);
	};

	return {
		image,
		image2,
		isLoading,
		text,
		handleGenerateClick,
		handleChangeText,
		handleRefreshClick,
		handleDialogClose,
		selectedImage,
		handleSelectFirstImage,
		handleSelectSecondImage,
	};
};

export const AiAvatar = ({
	openned,
	onClose,
	onChange,
	oneStyle,
}: AiAvatarProps) => {
	const {
		image,
		image2,
		isLoading,
		text,
		handleGenerateClick,
		handleChangeText,
		handleRefreshClick,
		handleDialogClose,
		selectedImage,
		handleSelectFirstImage,
		handleSelectSecondImage,
	} = useAiAvatar({
		openned,
		onClose,
		onChange,
		oneStyle,
	});

	return (
		<Modal open={openned} onClose={onClose}>
			<div className={styles.modal}>
				<div className={styles.container}>
					<h2 className={styles.title}>Avatar with AI</h2>
					<div className={styles.avatarContainer}>
						{isLoading && (
							<div className={styles.avatarSpinnerContainer}>
								<Spinner withLayout={false} />
							</div>
						)}
						{!isLoading && (
							<>
								<div
									className={cn(styles.imageContainer, {
										[styles.selected]: selectedImage && selectedImage === image,
									})}
								>
									<img
										src={image || placeholder}
										alt="avatar"
										className={cn(styles.placeholder)}
										onClick={handleSelectFirstImage}
									/>
									{selectedImage && selectedImage === image && (
										<img src={checkImg} alt="check" className={styles.check} />
									)}
								</div>
								{!oneStyle && (
									<div
										className={cn(styles.imageContainer, {
											[styles.selected]:
												selectedImage && selectedImage === image2,
										})}
									>
										<img
											src={image2 || placeholder}
											alt="avatar"
											className={cn(styles.placeholder)}
											onClick={handleSelectSecondImage}
										/>
										{selectedImage && selectedImage === image2 && (
											<img
												src={checkImg}
												alt="check"
												className={styles.check}
											/>
										)}
									</div>
								)}
							</>
						)}
					</div>
					<AiName value={text} onChange={handleChangeText} />
					{!selectedImage && (
						<InButton
							id="generate"
							isFilled
							className={styles.buttonG}
							onClick={handleGenerateClick}
							isDisabled={!text}
						>
							Generate
						</InButton>
					)}
					{selectedImage && (
						<div className={styles.btnsRow}>
							<InButton
								id="regenerate"
								className={styles.buttonRefresh}
								onClick={handleRefreshClick}
								isDisabled={isLoading}
							>
								<img
									src={refreshImg}
									alt="refresh"
									className={styles.refreshImg}
								/>
								<p>Regenerate</p>
							</InButton>
							<InButton
								id="save"
								isFilled
								className={styles.buttonG}
								onClick={handleDialogClose}
								isDisabled={isLoading || !selectedImage}
							>
								Done
							</InButton>
						</div>
					)}
					<img
						src={close}
						alt="close"
						className={styles.close}
						onClick={onClose}
					/>
				</div>
			</div>
		</Modal>
	);
};
