import {Slide, Dialog} from '@mui/material';
import {TransitionProps} from 'notistack';
import React, {useContext, useEffect, useState} from 'react';
import styles from './SearchPage.module.css';
import backImg from '../../images/back.svg';
import searchDeskImg from '../../images/search-desk.svg';
import closeImg from './images/close.svg';
import {webApi} from '../../api/webApi';
import {BotData, PaginationMeta} from '../../api/types';
import {useLocation, useNavigate} from 'react-router-dom';
import {Spinner} from '../../common-lib/src/components/Spinner/Spinner';
import {MessagesQty} from '../../common-lib/src/components/BotTile/MessagesQty';
import cn from 'classnames';
import {AppContext, VISITED_CHAD_ID} from '../../App';
import {chatStorage} from '../../chatStore/ChatStorage';
import {InButton} from '../../common-lib/src/components/InButton/InButton';
import {webEventsApi} from '../../amplitude/webEvents';

type Props = {
	onClose: () => void;
	isOpened: boolean;
};

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

let timeoutId: NodeJS.Timeout;

const isLastPage = (meta: PaginationMeta) => {
	return meta.pagination.page === meta.pagination.pageCount;
};

const startPage = 1;

export const SearchPage = ({onClose, isOpened}: Props) => {
	const [searchText, setSearchText] = useState('');
	const [bots, setBots] = useState<BotData[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const {pathname} = useLocation();
	const [page, setPage] = useState(startPage);
	const [isEnd, setIsEnd] = useState(false);
	const [elRef, setElRef] = useState<HTMLDivElement | null>(null);
	const [isEmptyResult, setIsEmptyResult] = useState(false);
	const inputRef = React.useRef<HTMLInputElement>(null);

	const {isMobile, featuredBots, setIsCreateOpened, user, setIsLoginOpened} =
		useContext(AppContext);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setBots([]);
		setSearchText(event.target.value);
	};

	const handleClear = () => {
		setSearchText('');
		setBots(featuredBots);
		setIsEmptyResult(false);
	};

	const categorySlug = pathname.includes('category_')
		? pathname.split('_')[1]
		: undefined;

	const isVisitedCategory = categorySlug === VISITED_CHAD_ID;

	useEffect(() => {
		if (isOpened) {
			setBots(featuredBots);
			setPage(startPage);
			setIsEnd(false);
			setSearchText('');
			setIsEmptyResult(false);

			setTimeout(() => {
				inputRef.current?.focus();
			}, 500);

			webEventsApi.searchAppear();
		}
	}, [isOpened]);

	const search = async (page: number) => {
		if (!searchText || !isOpened) {
			return;
		}

		try {
			setIsEmptyResult(false);
			setIsLoading(true);
			const result = isVisitedCategory
				? await webApi.searchByNameInIds(
						searchText,
						page,
						chatStorage.getKeys()
				  )
				: await webApi.searchByName(searchText, page);

			if (!result.data?.length) {
				setIsEmptyResult(true);
			}

			if (page === startPage) {
				setBots(result.data || []);
			} else {
				setBots([...bots, ...(result.data || [])]);
			}

			setIsEnd(isLastPage(result.meta));
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setPage(startPage);
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => search(startPage), 700);

		return () => clearTimeout(timeoutId);
	}, [searchText]);

	useEffect(() => {
		if (isEmptyResult && !isLoading && !bots.length) {
			webEventsApi.searchEmptyResult(searchText);
		}
	}, [isEmptyResult, isLoading, bots]);

	const handleTileClick = (bot: BotData) => {
		const index = bots.findIndex((b) => b.id === bot.id);
		webEventsApi.searchBotTapped(index, bot.attributes.name, searchText);
		onClose();
		setTimeout(() => {
			navigate(`/bot_${bot.id}/chat`);
		}, 100);
	};

	const nextPage = async () => {
		setPage(page + 1);
	};

	useEffect(() => {
		if (page > startPage && !isEnd) {
			search(page);
		}
	}, [page]);

	useEffect(() => {
		if (!bots.length) {
			return;
		}
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting) {
					nextPage();
				}
			},
			{threshold: 1}
		);

		if (elRef) {
			observer.observe(elRef);
		}

		return () => {
			if (elRef) {
				observer.unobserve(elRef);
			}
		};
	}, [elRef]);

	const handleGoBack = () => {
		webEventsApi.searchBack(searchText);
		onClose();
	};

	const title = searchText ? (
		<h2 className={styles.title}>Search results</h2>
	) : (
		<h2 className={styles.title}>Featured</h2>
	);

	const handleClick = () => {
		onClose();
		if (user) {
			setIsCreateOpened(true);
		} else {
			setIsLoginOpened(true);
		}
	};

	return (
		<Dialog
			fullScreen
			open={isOpened}
			onClose={onClose}
			TransitionComponent={Transition}
			sx={{
				height: '100dvh',
				backgroundColor: 'black',
				paddingBottom: '20px',
				boxSizing: 'border-box',
			}}
			className={styles.dialog}
		>
			<div className={styles.header}>
				<img
					src={backImg}
					alt="back"
					onClick={handleGoBack}
					className={styles.back}
				/>
				<div className={styles.inputContainer}>
					{!isMobile && (
						<img
							src={searchDeskImg}
							alt="search"
							className={styles.searchImg}
						/>
					)}
					<input
						type="text"
						className={styles.input}
						placeholder="Search by name"
						onChange={handleChange}
						value={searchText}
						ref={inputRef}
					/>
					<img
						src={closeImg}
						alt="clear"
						className={styles.clear}
						onClick={handleClear}
					/>
				</div>
			</div>
			<div className={styles.resultContainer}>
				<>
					{!isMobile && !!bots.length && !isLoading ? title : null}
					{!!bots.length &&
						bots.map((bot, i) => {
							return (
								<div key={bot.id} style={{width: '100%'}}>
									<div
										className={styles.resultRow}
										onClick={() => handleTileClick(bot)}
									>
										<img
											src={bot.attributes.avatarUrl}
											alt="bot avatar"
											className={styles.resultAvatar}
										/>
										<div className={styles.resultText}>
											<p className={styles.resultName}>{bot.attributes.name}</p>
											<p className={styles.resultBio}>{bot.attributes.bio}</p>
											<MessagesQty
												messagesQty={bot.attributes.messagesCount}
												className={cn('bot-tile__messages-qty', styles.qty)}
											/>
										</div>
									</div>
									<div
										ref={(el) => {
											if (el) {
												setElRef(el);
											}
										}}
									></div>
								</div>
							);
						})}

					{isLoading && <Spinner withLayout={false} noTopMargin />}
					{isEmptyResult && !isLoading && !bots.length && (
						<div>
							<p className={styles.empty}>Oops! Our bot radar came up empty.</p>
							<p className={styles.empty}>No bot matches your query.</p>
							<InButton
								id="create-bot"
								isFilled
								className="create-btn"
								onClick={handleClick}
							>
								<p className={styles.letsCreate}>Create Bot</p>
							</InButton>
						</div>
					)}
				</>
			</div>
		</Dialog>
	);
};
