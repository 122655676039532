import {useEffect, useState} from 'react';

const MOBILE_WIDTH = 630;

type Size = {
	width: number;
	height: number;
	isMobile: boolean;
};

let sharedState: Size = {
	width: window.innerWidth,
	height: window.innerHeight,
	isMobile: window.innerWidth <= MOBILE_WIDTH,
};

let subscribers: Array<(state: Size) => void> = [];
let isInitialized = false;

function initializeResizeListener() {
	function handleResize() {
		sharedState = {
			width: window.innerWidth,
			height: window.innerHeight,
			isMobile: window.innerWidth <= MOBILE_WIDTH,
		};
		subscribers.forEach((subscriber) => subscriber(sharedState));
	}

	window.addEventListener('resize', handleResize);
	handleResize();

	return () => {
		window.removeEventListener('resize', handleResize);
	};
}

export const useDisplaySize = () => {
	const [state, setState] = useState(sharedState);

	useEffect(() => {
		if (!isInitialized) {
			initializeResizeListener();
			isInitialized = true;
		}
		subscribers.push(setState);

		return () => {
			subscribers = subscribers.filter((subscriber) => subscriber !== setState);
			if (subscribers.length === 0) {
				isInitialized = false;
			}
		};
	}, []);

	return state;
};
