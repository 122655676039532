import {voicesData} from '../../../../common-lib/src/data/voicesData';
import {VoiceDataType} from '../../../../common-lib/src/data/voicesData.types';
import styles from './VoiceDialog.module.css';
import {useEffect, useState} from 'react';
import {DoubleSwitcher} from '../../../../common-lib/src/components/DoubleSwitcher/DoubleSwitcher';
import {useVoiceSelector} from '../../../../common-lib/src/components/Selectors/VoiceSelectorRow/useVoiceSelector';
import {VoiceRow} from '../../../../common-lib/src/components/VoiceRow/VoiceRow';
import {InButton} from '../../../../common-lib/src/components/InButton/InButton';
import {MobileDialog} from '../MobileDialog/MobileDialog';

type Props = {
	openned: boolean;
	onClose: () => void;
	voice: VoiceDataType;
	setVoice: (voice: VoiceDataType) => void;
};

export const VoiceDialog = ({openned, onClose, voice, setVoice}: Props) => {
	const {playing, setPlaying} = useVoiceSelector();
	const [isFemine, setIsFemine] = useState<boolean>(voice?.isFemale ?? true);
	const [isForceChanged, setIsForceChanged] = useState<boolean>(false);

	useEffect(() => {
		if (isForceChanged) {
			setVoice(
				voicesData.find((voice) => voice.isFemale === isFemine) as VoiceDataType
			);
		}
	}, [isFemine]);

	return (
		<MobileDialog
			openned={openned}
			onClose={onClose}
			title="Voice"
			contentClassName={styles.content}
		>
			<>
				<DoubleSwitcher
					left="Femine"
					right="Masculine"
					isLeft={isFemine}
					onSwitch={(isLeft) => {
						setIsFemine(isLeft);
						setIsForceChanged(true);
					}}
					className="voice-type-switcher"
				/>
				{voicesData
					.filter((data) => data.isFemale === isFemine)
					.map((data) => {
						return (
							<VoiceRow
								key={data.name}
								voice={data}
								selected={voice?.name === data.name}
								onSelect={() => setVoice(data)}
								isPlaying={playing?.name === data.name}
								onPlay={setPlaying}
								className={styles.voiceRow}
							/>
						);
					})}
				<InButton
					id="select-voice"
					onClick={onClose}
					className={styles.btn}
					isFilled
				>
					Continue
				</InButton>
			</>
		</MobileDialog>
	);
};
