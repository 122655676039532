import {useContext} from 'react';
import {ChatMainWithLazyLoad} from '../../../../common-lib/src/components/ChatCommon/ChatMain/ChatMain';
import './BotPage.css';
import {
	backImg,
	otherImg,
	reportImg,
	editImg,
	shareImg,
	sendImg,
	sendActiveImg,
	clearImg,
	deleteImg,
	profileImg,
	deleteRedImg,
} from './images';
import {SharingModal} from './SharingModal/SharingModal';
import {FeedbackModal} from '../../../Feedback/Feedback';
import {AppContext} from '../../../../App';
import {BotPageProps} from './BotPage.types';
import {LimitInfoText} from './LimitInfo/LimitInfoText';
import {InButton} from '../../../../common-lib/src/components/InButton/InButton';
import {SendMeBtn} from './SendMeBtn/SendMeBtn';
import {LlmSelector} from './LlmSelector/LlmSelector';
import {Spinner} from '../../../../common-lib/src/components/Spinner/Spinner';
import {useConfirmation} from '../../../../common-lib/src/hooks/useConfirmation/useConfirmation';
import {MuteBtn} from './DesktopBotPage';
import {ContinueBtn} from './ContinueBtn/ContinueBtn';

export const MobileBotPage = (props: BotPageProps) => {
	const {
		handleGoBack,
		bot,
		handleShareClick,
		handleOpen,
		isOtherActionsOpened,
		handleClearChat,
		handleDeleteBot,
		handleDeleteChat,
		setIsFeedbackOpened,
		chatRef,
		isTyping,
		context,
		inputRef,
		inputHeight,
		inputValue,
		handleInputChange,
		handleKeyDown,
		handleSendMessage,
		isSharingOpened,
		setIsSharingOpened,
		isFeedbackOpened,
		handleSubmitReport,
		handleFocus,
		handleBlur,
		actions,
		isChatLimited,
		limitTimeLeft,
		handleEditBot,
		handleGoProfile,
		userActions,
		handleSelectLlm,
		selectedLlm,
		availableLlms,
		isMuted,
		handleSetIsMuted,
		isGlobalLoading,
		pagination,
		handleContinue,
	} = props;
	const {handleLoginOrPaywall} = useContext(AppContext);

	const {open, ConfirmationDialog} = useConfirmation({
		title: 'Delete Bot',
		text: 'Are you sure you want to delete this bot?',
		okButtonText: 'Delete',
		onSubmit: handleDeleteBot,
	});

	const {
		open: openDeleteChat,
		ConfirmationDialog: ConfirmationDialogDeleteChat,
	} = useConfirmation({
		title: 'Delete Chat',
		text: 'Are you sure you want to delete this chat? All messages will be deleted.',
		okButtonText: 'Delete',
		onSubmit: handleDeleteChat,
	});

	const {open: openClearChat, ConfirmationDialog: ConfirmationDialogClearChat} =
		useConfirmation({
			title: 'Clear Chat',
			text: 'Are you sure you want to clear this chat? All messages will be deleted.',
			okButtonText: 'Clear',
			onSubmit: handleClearChat,
		});

	const handleSendMessageClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();
		inputRef.current?.focus();
		handleSendMessage(inputValue);
	};

	return (
		<div className="mobile-bot-page">
			{isGlobalLoading && <Spinner />}
			<ConfirmationDialog />
			<ConfirmationDialogDeleteChat />
			<ConfirmationDialogClearChat />
			<div className="header">
				<img src={backImg} alt="back" className="back" onClick={handleGoBack} />
				<div className="profile">
					<img
						src={bot.attributes.avatarUrl}
						alt={`${bot.attributes.name} - 44 - ${bot.attributes.createdAt}`}
						className="avatar"
						title={bot.attributes.name}
						onClick={handleGoProfile}
					/>
					<div className="texts">
						<h1 className="name" onClick={handleGoProfile}>
							{bot.attributes.name}
						</h1>
					</div>
				</div>
				<div className="actions">
					<LlmSelector
						handleSelectLlm={handleSelectLlm}
						selectedLlm={selectedLlm}
						llms={availableLlms}
					/>
					<MuteBtn isMuted={isMuted} handleSetIsMuted={handleSetIsMuted} />
					<div className="action" id="bot-actions" onClick={handleOpen}>
						<img src={otherImg} alt="share" />
						{isOtherActionsOpened && (
							<div className="actions__box">
								{bot.attributes.isOwner && (
									<div className="action__row" onClick={handleEditBot}>
										<img src={editImg} alt="edit bot" />
										<p>Edit Bot</p>
									</div>
								)}
								<div className="action__row" onClick={handleGoProfile}>
									<img src={profileImg} alt="profile page" />
									<p>Bot profile</p>
								</div>
								<div className="action__row" onClick={handleShareClick}>
									<img src={shareImg} alt="share" />
									<p>Share</p>
								</div>
								<div className="action__row" onClick={openClearChat}>
									<img src={clearImg} alt="clear chat" />
									<p>Clear Chat</p>
								</div>
								<div className="action__row" onClick={openDeleteChat}>
									<img src={deleteImg} alt="delete chat" />
									<p className="action__row_text">Delete Chat</p>
								</div>
								<div
									className="action__row"
									onClick={() => setIsFeedbackOpened(true)}
								>
									<img src={reportImg} alt="report bot" />
									<p className="action__row_text-red">Report Bot</p>
								</div>
								{bot.attributes.isOwner && (
									<div className="action__row" onClick={open}>
										<img src={deleteRedImg} alt="delete bot" />
										<p className="action__row_text-red">Delete Bot</p>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="content" style={{height: `100%`}} ref={chatRef}>
				<ChatMainWithLazyLoad
					isLoading={isTyping}
					messages={context}
					actions={actions}
					userActions={userActions}
					pagination={pagination}
				/>
			</div>
			{!isChatLimited && (
				<div className="input-field">
					<textarea
						ref={inputRef}
						name="input"
						id="input"
						className="input"
						style={{height: `${inputHeight}px`}}
						onChange={handleInputChange}
						value={inputValue}
						placeholder={
							isChatLimited
								? 'You have reached messages limit'
								: 'Type a message'
						}
						onKeyDown={handleKeyDown}
						onFocus={handleFocus}
						onBlur={handleBlur}
					/>
					{!inputValue.trim().length &&
						!actions?.shouldShowVoteNotification &&
						!!bot.attributes.generatePhotos && (
							<SendMeBtn
								onClick={(text: string) => handleSendMessage(`Send me ${text}`)}
								hasVideo={bot.attributes.generateVideos}
							/>
						)}
					{inputValue.trim() ? (
						<img
							src={inputValue.trim() ? sendActiveImg : sendImg}
							alt="send"
							className="send"
							onClick={handleSendMessageClick}
						/>
					) : (
						<ContinueBtn onClick={handleContinue} disabled={isTyping} />
					)}
				</div>
			)}
			{isChatLimited && (
				<div className="chat-limited">
					<h3 className="chat-limited__title">You've reached messages limit</h3>
					<LimitInfoText data={limitTimeLeft} />
					<InButton
						id="chat-limited__button"
						isFilled
						onClick={handleLoginOrPaywall}
						className="chat-limited__btn"
					>
						Continue right now
					</InButton>
				</div>
			)}
			<SharingModal
				isOpened={isSharingOpened}
				onClose={() => setIsSharingOpened(false)}
				link={window.location.href}
			/>
			<FeedbackModal
				isOpen={isFeedbackOpened}
				onClose={() => setIsFeedbackOpened(false)}
				onSubmit={handleSubmitReport}
			/>
		</div>
	);
};
