export const splitMessageToItalic = (message?: string) => {
	const textsArray = message?.split('') || [];
	let words = '';
	let hasStar = false;
	const texts: {text: string; italic: boolean}[] = [];
	for (let i = 0; i < textsArray.length; i++) {
		const char = textsArray[i];
		if (char === '*') {
			if (words) {
				texts.push({text: words, italic: hasStar});
				words = '';
			}
			hasStar = !hasStar;
		} else {
			words += char;
		}

		if (i === textsArray.length - 1) {
			texts.push({text: words, italic: hasStar});
			words = '';
		}
	}
	return texts;
};
