import styles from './ContinueBtn.module.css';
import continueImg from './images/continue.svg';

type ContinueBtnProps = {
	onClick: () => void;
	disabled: boolean;
};

export const ContinueBtn = ({onClick, disabled}: ContinueBtnProps) => {
	return (
		<img
			src={continueImg}
			alt="continue"
			className={styles.continueBtn}
			onClick={onClick}
			style={{opacity: disabled ? 0.5 : 1}}
		/>
	);
};
