import {CategoryAndBots} from '../../../api/apiHelper';
import {BotData} from '../../../api/types';
import {TilesPlateV2} from '../../TilesPlateV2/TilesPlateV2';

type Props = {
	category: CategoryAndBots;
	bots: BotData[];
	showRandomTile?: boolean;
};

export const CategoryV2Inner = ({
	category,
	bots,
	showRandomTile = false,
}: Props) => {
	return (
		<>
			<TilesPlateV2
				categoryData={category}
				bots={bots}
				showRandomTile={showRandomTile}
			/>
		</>
	);
};
