import {useEffect, useState} from 'react';
import {InButton} from '../../common-lib/src/components/InButton/InButton';
import {USE_COOKIES_CONSENT_KEY} from './constants';
import './CookieBar.css';
import cookieImg from './images/cookie.svg';
import {getCookie, updateCookie} from './utils';
import {InCheckbox} from '../../common-lib/src/components/InCheckbox/InCheckbox';

export const CookieBar = () => {
	const cookie = getCookie(USE_COOKIES_CONSENT_KEY);
	const [isCookieBarOpen, setIsCookieBarOpen] = useState(!cookie);
	const [isManageCookiesOpen, setIsManageCookiesOpen] = useState(false);
	const [isManageAllowed, setIsManageAllowed] = useState(true);

	const handleManageClick = () => {
		setIsManageCookiesOpen(true);
	};

	useEffect(() => {
		const newCookie = getCookie(USE_COOKIES_CONSENT_KEY);
		if (newCookie) {
			setIsCookieBarOpen(false);
		}
	}, []);

	const handleAgreeClick = () => {
		updateCookie(USE_COOKIES_CONSENT_KEY, isManageAllowed);
		if (!isManageAllowed) {
			try {
				//@ts-ignore
				window['disableYaCounter90360441'] = true;
			} catch (e) {
				console.error(e);
			}
		}
		setIsCookieBarOpen(false);
		//TODO: add event tracking
	};

	if (!isCookieBarOpen) {
		return null;
	}

	return (
		<div className={`cukie-bar`}>
			<div className="cukie-bar__content">
				<img src={cookieImg} alt="cookie" className="cukie-img" />
				<div className="cukie-bar__left">
					<h3 className="cukie-bar__title">This website uses cookies</h3>
					<div className="cukie-bar__text">
						We use cookies to make the website responsive, easy to use, and to
						analyze our traffic.
					</div>
				</div>
				<div className="cukie-bar__right row">
					{!isManageCookiesOpen && (
						<InButton
							id="cukie-no"
							className="cukie-bar__no"
							onClick={handleManageClick}
						>
							Manage Cookies
						</InButton>
					)}
					{isManageCookiesOpen && (
						<div className="cukie-bar__check-row">
							<InCheckbox
								checked={isManageAllowed}
								onChange={() => setIsManageAllowed(!isManageAllowed)}
								className="cukie-bar__check-box"
							/>
							<p className="cukie-bar__check-text">Optional cookies allowed</p>
						</div>
					)}
					<InButton
						id="cukie-agree"
						isFilled
						className="cukie-bar__agree"
						onClick={handleAgreeClick}
					>
						Accept
					</InButton>
				</div>
			</div>
		</div>
	);
};
